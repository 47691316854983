import React, { useEffect } from "react"

import "./cms.css"

const Index = () => {
  useEffect(() => {
    if (typeof window === "object") {
      const CMS = require("netlify-cms-app")
      CMS.init()
    }
  }, [])

  return (
    <>
      <img
        style={{
          position: "absolute",
          top: "auto",
          bottom: "20px",
          left: "20px",
        }}
        src="https://api.netlify.com/api/v1/badges/57f3089c-d618-407d-ae41-8f10f0310edd/deploy-status"
      />
    </>
  )
}

export default Index
